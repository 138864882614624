<template>
  <!-- test -->
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>数据展示</el-breadcrumb-item>
      <el-breadcrumb-item>单位工程情况</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区 -->
    <!-- 获取甩项进度 -->
    <el-card
      v-if="
        this.roleName != '资料员' &&
        this.roleName != '资料主管' &&
        this.roleName != '试验员' &&
        this.roleName != '试验主管'
      "
    >
      <div class="title">单位工程甩项总进度</div>
      <div
        class="progess"
        v-for="(item, index) in projectProgess"
        :key="index"
        style="margin-bottom: 15px; margin-left: 100px"
      >
        <div>{{ item.projectName }}</div>
        <div class="progesswidth" style="margin-right: 200px">
          <el-progress
            :text-inside="true"
            :stroke-width="26"
            :percentage="item.projectProgress"
            @click="show(item.partProject)"
          />
        </div>
      </div>
    </el-card>
    <el-dialog v-model="optionJilt" title="分部甩项总进度" width="80%" center>
      <el-card>
        <div
          class="progess"
          v-for="(item, index) in projectProgessPart"
          :key="index"
          style="margin-bottom: 15px; margin-left: 100px"
        >
          <div>{{ item.part_project_name }}</div>
          <div class="progesswidth" style="margin-right: 200px">
            <el-progress
              :text-inside="true"
              :stroke-width="26"
              :percentage="item.part_progress"
            />
          </div>
        </div>
      </el-card>
    </el-dialog>
  </div>
  <!--单位工程施工计划执行情况  -->
  <el-card
    v-if="
      this.roleName != '资料员' &&
      this.roleName != '资料主管' &&
      this.roleName != '试验员' &&
      this.roleName != '试验主管'
    "
    style="margin-top: 30px"
  >
    <div class="title">单位工程施工计划执行情况</div>
    <div class="allView">
      <!-- 固定列 -->
      <div class="left">
        <tr v-for="(item, index) in projectName" :key="index">
          <td align="center" valign="middle">
            <div class="tdLeft">{{ item.projectName }}</div>
          </td>
        </tr>
      </div>
      <!-- 移动列 -->
      <div class="move">
        <tr v-for="(data, i) in projectName" :key="i">
          <td
            v-for="(item, index) in time"
            :key="index"
            style="border-top: none"
          >
            <div class="td">
              <div :style="'opacity:' + (i == 0 ? 1 : 0)">{{ item }}</div>
              <!-- 计划时间长度 -->
              <div v-if="item === data.startTime">
                <div>
                  <div
                    class="bg"
                    :style="'width:' + data.endTime *82 + 'px'"
                  ></div>
                </div>
                <!-- 不是从整月开始 -->
                <div v-if="data.enable == 0">
                  <div class="bg0" style="width: 9px"></div>
                </div>
              </div>
              <!-- 实际执行的时间和进度 -->
              <div v-if="item == data.progressStartTime">
              
                <div v-if="data.completePercent > 100">
                  <div
                    class="pro"
                    :style="'width:' + data.progressEndTime * 83 + 'px'"
                  >
                    {{ data.completePercent }}%
                  </div>
                </div>
                <div v-if="data.completePercent <= 100">
                  <div
                    class="pro"
                    :style="'width:' + data.progressEndTime * 82 + 'px'"
                  >
                    {{ data.completePercent }}%
                  </div>
                </div>
                  <!-- 不是从整月开始 -->
                <div v-if="data.progressEnable == 0">
                  <div class="bg00" style="width: 9px"></div>
                </div>
              </div>
            </div>
          </td>
          <td>
            <button
              class="button1"
              @click="showPart(this.cur.curItemId, data.projectName)"
              style="margin-left: 5px; font-size: 10px"
            >
              分部
            </button>
          </td>
        </tr>
      </div>
    </div>
  </el-card>
  <el-dialog v-model="optionProject" title="分部工程" width="100%" center>
    <el-card
    v-if="
      this.roleName != '资料员' &&
      this.roleName != '资料主管' &&
      this.roleName != '试验员' &&
      this.roleName != '试验主管'
    "
    style="margin-top: 30px"
  >
    <div class="title">分部工程施工计划执行情况</div>
    <div class="allView">
      <!-- 固定列 -->
      <div class="left">
        <tr v-for="(item, index) in projectNameDivision" :key="index">
          <td align="center" valign="middle">
            <div class="tdLeft">{{ item.partProjectName }}</div>
          </td>
        </tr>
      </div>
      <!-- 移动列 -->
      <div class="move">
        <tr v-for="(data, i) in projectNameDivision" :key="i">
          <td
            v-for="(item, index) in time"
            :key="index"
            style="border-top: none"
          >
            <div class="td">
              <div :style="'opacity:' + (i == 0 ? 1 : 0)">{{ item }}</div>
              <!-- 计划时间长度 -->
              <div v-if="item === data.startTime">
                <div>
                  <div
                    class="bg"
                    :style="'width:' + data.endTime * 82 + 'px'"
                  ></div>
                </div>
                <!-- 不是从整月开始 -->
                <div v-if="data.enable == 0">
                  <div class="bg0" style="width: 9px"></div>
                </div>
              </div>
              <!-- 实际执行的时间和进度 -->
              <div v-if="item == data.progressStartTime">
              
                <div v-if="data.completePercent > 100">
                  <div
                    class="pro"
                    :style="'width:' + data.progressEndTime * 83 + 'px'"
                  >
                    {{ data.completePercent }}%
                  </div>
                </div>
                <div v-if="data.completePercent <= 100">
                  <div
                    class="pro"
                    :style="'width:' + data.progressEndTime * 82 + 'px'"
                  >
                    {{ data.completePercent }}%
                  </div>
                </div>
                  <!-- 不是从整月开始 -->
                <div v-if="data.progressEnable == 0">
                  <div class="bg00" style="width: 0px"></div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </div>
    </div>
  </el-card>
  </el-dialog>

  <!-- 单位工程每月完成情况 -->
  <el-card
    v-if="
      this.roleName != '资料员' &&
      this.roleName != '资料主管' &&
      this.roleName != '试验员' &&
      this.roleName != '试验主管'
    "
  >
    <div class="title">单位工程施工计划每月完成情况</div>
    <div class="allView">
      <!-- 固定列 -->
      <div class="left">
        <tr v-for="(item, index) in projectName1" :key="index">
          <td align="center" valign="middle">
            <div class="tdLeft">{{ item.projectName }}</div>
          </td>
        </tr>
      </div>
      <!-- 移动列 -->
      <div class="move">
        <tr v-for="(data, i) in projectName1" :key="i">
          <td
            v-for="(item, index) in time"
            :key="index"
            style="border-top: none"
          >
            <div class="td">
              <div :style="'opacity:' + (i == 0 ? 1 : 0)">{{ item }}</div>
              <div v-if="data.startTime[index] === item">
                <div class="bg1" style="width: 93 px;">
                  <div
                    class="progress"
                    :style="
                      'width:' +
                      (data.num[index] > 100 ? 103 : data.num[index]) +
                      '%'
                    "
                    >
                  </div>
                  <div style="position: relative;top:-15px;text-align:center;font-size:1px">
                    {{ data.num[index] }}%
                  </div>
                </div>
              </div>
              <div v-else-if="data.progressMonth[index]===item ">
                  <div
                  class="progress"
                  style="width:100%;text-align:center"
                  >
                  非期内工程
                  </div>
                </div>
              </div>
          </td>
           <td>
            <button
              class="button1"
              @click="showPartMonth(this.cur.curItemId, data.projectName)"
              style="margin-left: 5px; font-size: 10px"
            >
              分部
            </button>
          </td>
        </tr>
      </div>
    </div>
  </el-card>
  <el-dialog v-model="optionProjectMonth" title="单位分部工程施工计划每月完成情况" width="100%" center>
      <el-card
    v-if="
      this.roleName != '资料员' &&
      this.roleName != '资料主管' &&
      this.roleName != '试验员' &&
      this.roleName != '试验主管'
    "
  >
    <div class="title">单位分部工程施工计划每月完成情况</div>
    <div class="allView">
      <!-- 固定列 -->
      <div class="left">
        <tr v-for="(item, index) in projectNameDivisionMonth" :key="index">
          <td align="center" valign="middle">
            <div class="tdLeft">{{ item.partProjectName }}</div>
          </td>
        </tr>
      </div>
      <!-- 移动列 -->
      <div class="move">
        <tr v-for="(data, i) in projectNameDivisionMonth" :key="i">
          <td
            v-for="(item, index) in time"
            :key="index"
            style="border-top: none"
          >
            <div class="td">
              <div :style="'opacity:' + (i == 0 ? 1 : 0)">{{ item }}</div>
              <div v-if="data.startTime[index] === item">
                <div class="bg1" style="width: 93 px;">
                  <div
                    class="progress"
                    :style="
                      'width:' +
                      (data.num[index] > 100 ? 103 : data.num[index]) +
                      '%'
                    "
                  >
                   
                  </div>
                    <div style="position: relative;top:-15px;text-align:center;font-size:1px">
                    {{ data.num[index] }}%
                  </div>
                </div>
              </div>
              <div v-else-if="data.progressMonth[index]===item ">
                  <div
                  class="progress"
                  style="width:100%;text-align:center"
                  >
                  非期内工程
                  </div>
                </div>
            </div>
          </td>
        </tr>
      </div>
    </div>
  </el-card>
  </el-dialog>
  <!-- 材料理论用量 -->
  <el-card
    v-if="
      this.roleName != '资料员' &&
      this.roleName != '资料主管' &&
      this.roleName != '试验员' &&
      this.roleName != '试验主管'
    "
  >
    <div class="title">材料理论用量</div>
    <el-row :gutter="20">
          <el-col :span="10">
              <el-input 
              type="text"
              v-model="cur3.query" 
              placeholder="请输入材料名"  />
          </el-col>
      <el-button icon="el-icon-search" @click="getMaterialConsumption()">搜索</el-button>
  </el-row>
    <div class="allView">
      <!-- 固定列 -->
      <div class="left">
        <tr v-for="(item, index) in projectName3" :key="index">
          <td align="center" valign="middle">
            <div class="tdLeft1">{{ item.materialName }}</div>
          </td>
        </tr>
      </div>
      <div class="left">
        <tr v-for="(item, index) in projectName3" :key="index">
          <td align="center" valign="middle">
            <div class="tdLeft">
              <div style="color: #d1cbcb; font-size: 15px; margin-bottom: 10px">
                每月用量合计
              </div>
              {{ item.progressConsumption }}
            </div>
          </td>
        </tr>
      </div>
      <div class="left">
        <tr v-for="(item, index) in projectName3" :key="index">
          <td align="center" valign="middle">
            <div class="tdLeft">
              <div style="color: #d1cbcb; font-size: 15px; margin-bottom: 10px">
                工程理论用量
              </div>
              {{ item.planConsumption }}
            </div>
          </td>
        </tr>
      </div>

      <!-- 移动列 -->
      <div class="move">
        <tr v-for="(data, i) in projectName3" :key="i">
          <td
            v-for="(item, index) in time"
            :key="index"
            style="border-top: none"
          >
            <div class="td">
              <div :style="'opacity:' + (i == 0 ? 1 : 0)">{{ item }}</div>
              <div v-if="data.time[index] === item">
                <div class="bg1" style="width: 93 px;font-size: 10px">
                  <div
                    class="progress"
                    :style="
                      'width:' +
                      (data.progressToPlanRadio[index] > 100 ? 110 : data.progressToPlanRadio[index]) +
                      '%'
                    "
                  >
                  <div v-if="data.progressToPlanRadio[index] !=0&&data.monthlyProgressConsumption[index] !=0">
                 {{data.progressToPlanRadio[index]+'%' }} 
                </div>
                </div>
                <div style="font-size: px;width:auto">
                  理论：{{ data.monthlyPlanConsumption[index]}}
                  实际：{{ data.monthlyProgressConsumption[index] }}
                </div>
                
              </div>
              </div>
              <div v-if="data.time[index] != item && data.monthlyProgressConsumption[index] !=0">
                <div class="progress" :style="'width:100%'">
                      </div>
                <div style="font-size: 9px;width:auto;height: auto;">
                理论：{{ data.monthlyPlanConsumption[index]}}
                实际：{{ data.monthlyProgressConsumption[index] }}
                </div>
              </div>
              
            </div>
          </td>
        </tr>
      </div>
    </div>
  </el-card>
</template>

<script>
import _ from 'lodash';
import LoginVue from "../Login.vue";
export default {
  props: ["itemName"],
  watch: {
    itemName: function (newV, oldV) {
      this.getMaterialConsumption();
    },
  },
  data() {
    return {
      roleName: "",
      // 甩项进度
      projectProgess: [],
      // 甩项进度分部
      projectProgessPart: [],
      optionJilt: false,
      optionProject: false,
      optionProjectMonth:false,
      time: [],
      time3:[],
      // 单位计划
      projectName: [],
       //单位工程施工计划 分部
      projectNameDivision: [],

      //单位每月工程施工计划
      projectName1: [],
      //每月单位工程施工计划 分部
      projectNameDivisionMonth: [],

   
      // 材料理论用量
      projectName3: [],
      cur3: {
        curItemId:"",
        query:"",
        materialName:"",
      },

      curPart: {},
      //  甩项进度
      curJilt: {},
      curJiltPart: {},
      curSituation: {},
      cur:{
      },
    };
  },
  mounted() {      
    setTimeout(() => {
      this.jurisdiction();
    }, 100),
      setTimeout(() => {
        if (
          this.roleName != "资料员" &&
          this.roleName != "资料主管" &&
          this.roleName != "试验员" &&
          this.roleName != "试验主管"
        ) {
          this.getProjectCompletion();
          this.getJiltProgress();
          this.getProjectCompletionSituation();
          this.getMaterialConsumption();
        }
      }, 300);
  },
  methods: {
    fun(){
      console.log(1111);
    },
    //权限
    async jurisdiction() {
      this.roleName = window.sessionStorage.getItem("roleName");
    },
     //单位工程计划分部弹窗
    async showPart(curItemId, projectName) {
      this.optionProject = true;
      this.curPart.curItemId = curItemId;
      this.curPart.projectName = projectName;

      const { data: res } = await this.$http.get(
        "/reportForm/getPartProjectCompletion",
        { params: this.curPart }
      );
      if (res.code != 200) {
        return this.$message.error(res.msg);
      } else {
        this.projectNameDivision = res.data;
      }
    },
    // 每月单位工程计划分部弹窗
     async showPartMonth(curItemId, projectName) {
      this.optionProjectMonth = true;
      this.curPart.curItemId = curItemId;
      this.curPart.projectName = projectName;

      const { data: res } = await this.$http.get(
        "/reportForm/getPartProjectCompletionSituation",
        { params: this.curPart }
      );
      if (res.code != 200) {
        return this.$message.error(res.msg);
      } else {
        this.projectNameDivisionMonth = res.data;
      }
    },
    //单位工程计划
    async getProjectCompletion() {
      this.cur.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "/reportForm/getProjectCompletion",
        { params: this.cur }
      );
      if (res.code != 200) {
        return this.$message.error(res.msg);
      } else {
        this.time = res.data.firstTimeAndEndTime;
        this.projectName = res.data.newSingleProjectCompletionList;
      }
    },
    // 甩项总进度
    async getJiltProgress() {
      this.curJilt.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "/reportForm/getJiltProgress",
        { params: this.curJilt }
      );
      if (res.code != 200) {
        return this.$message.error(res.msg);
      } else {
        this.projectProgess = res.data;
      }
    },
    show(item) {
      this.optionJilt = true;
      this.projectProgessPart = item;
    },
    //单位工程每月计划
    async getProjectCompletionSituation() {
      this.curSituation.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "/reportForm/getProjectCompletionSituation",
        { params: this.curSituation }
      );
      if (res.code != 200) {
        return this.$message.error(res.msg);
      } else {
        this.projectName1 = res.data;
      }
    },
    // 材料理论用量
    async getMaterialConsumption(){
      this.cur3.curItemId = window.sessionStorage.getItem("activeItem");
      console.log(window.sessionStorage.getItem("activeItem"));
      console.log(this.cur3.materialName);
        const { data: res } = await this.$http.get(
        "/reportForm/getMaterialConsumption",
        { params: this.cur3}
        )
       if (res.code != 200) {
        return this.$message.error(res.msg);
      } else {
        this.projectName3 = res.data.data;
        this.materialName=res.data.materialName;
        this.time3 =res.data.time;
      }
    },

  },
};
</script>

<style lang="less" scoped>
.progess {
  display: flex;
  /* 在弹性盒对象的 <div> 元素中的各项周围留有空白 */
  justify-content: space-between;
}
.progesswidth {
  width: 800px;
}
.title {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  line-height: 50px;
}
.bg {
  height: 15px;
  border-top: 6px solid #0062cc;
  border-bottom: 6px solid #0062cc;
  background-color: #0062cc;

  .progress {
    text-align: center;
    font-size: 1px;
    height: 15px;
    line-height: 15px;
    background-color: #cc3914;
    color: #ffffff;
  }
}
.bg0 {
  top: -28px;
  position: relative;
  height: 22px;
  border-top: 6px solid #687591;
  border-bottom: 6px solid #687591;
  background-color: #687591;
}
.bg00 {
  top: -20px;
  position: relative;
  height: 13px;
  border-top: 6px solid #0062cc;
  border-bottom: 6px solid #0062cc;
  background-color: #0062cc;
}
.bg1 {
  margin: 5px;
  margin-left: 7px;
  height: 15px;
  border-top: 6px solid #0062cc;
  border-bottom: 6px solid #0062cc;
  background-color: #0062cc;
}

.progress {
    text-align: center;
    font-size: 1px;
    height: 15px;
    line-height: 15px;
    background-color: #cc3914;
    color: #ffffff;
  }
.pro1 {
  position: relative;
  top: -28px;
  margin-top: 7px;
  text-align: center;
  font-size: 12px;
  height: 15px;
  line-height: 15px;
  background-color: #cc3914;
  color: #ffffff;
}
.pro {
  margin-top: 7px;
  text-align: center;
  font-size: 12px;
  height: 15px;
  line-height: 15px;
  background-color: #cc3914;
  color: #ffffff;
}
.allView {
  width: 75vw;
  padding: 3vw 2vw;
  display: flex;
  margin-bottom: 30px;
  background-color: #687591;
  padding-right: 3vw;
  border-radius: 5px;
  box-shadow: 3px 3px 8px 3px rgba(0, 0, 0, 0.3);
  overflow-x: scroll;
  .left {
  }

  .move {
    border-spacing: 0;
  }

  :deep(.el-button--small) {
    min-height: 28px;
    padding: 0px 0px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
  }

  .td {
    width: 81px;
    font-size: 15px;
    text-align: center;
    height: 81px;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-left: none;
  }
  .button1 {
    position: relative;
    top: 20px;
  }
  .tdLeft {
    width: 91px;
    text-align: center;
    font-size: 13px;
    height: 81px;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
  .tdLeft1 {
    width: 180px;
    text-align: center;
    font-size: 13px;
    height: 81px;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
</style>
